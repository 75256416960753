import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import Toitu from '../images/Toitu_enviromark_Gold.png'

import SEOPage from '../components/SEO/Page'

class ArticleTemplate extends React.Component {
  render() {
    const { location, data, pageContext } = this.props
    const { next } = pageContext

    const newsArticle = data.allPrismicNewsArticle.edges[0].node.data
    const date = data.allPrismicNewsArticle.edges[0].node.first_publication_date

    return (
      <Layout location={location}>
        <SEOPage
          title={newsArticle.title && newsArticle.title}
          location={location}
        />
        <div className="lg:mt-16 lg:pt-1 mb-32 lg:mb-60 lg:w-3/5 mx-auto leading-lg tracking-lg">
          <div className="mb-20">
            <div className="mb-12 lg:mb-20">
              {newsArticle.image.url && (
                <div className="ratio ratio-5/8 mb-5">
                  <img
                    className="w-full"
                    src={newsArticle.image.url}
                    alt={newsArticle.image.alt}
                  />
                </div>
              )}
              <div className="flex lg:items-center mx-4 lg:mx-0">
                <div className="w-1/2 lg:w-auto text-sm lg:mr-12">
                  {newsArticle.category.document[0].data.title && (
                    <p className="font-medium">
                      {newsArticle.category.document[0].data.title}
                    </p>
                  )}
                  {newsArticle.category.document[0].data.subtitle && (
                    <p>{newsArticle.category.document[0].data.subtitle}</p>
                  )}
                </div>
                {newsArticle.category.uid === 'sustainable' && (
                  <div className="w-1/2 lg:w-auto flex justify-end lg:block">
                    <img className="w-12" src={Toitu} alt="Toitu logo" />
                  </div>
                )}
              </div>
            </div>

            <div className="mx-4 lg:mx-0 mb-20">
              {newsArticle.title && (
                <h1 className="tracking-2xl mb-4 text-4xl font-light leading-tight text-grey">
                  {newsArticle.title}
                </h1>
              )}
              {date && <p className="mb-10">{date}</p>}
              {newsArticle.lead.test && (
                <h2 className="tracking-2xl leading-lg font-normal mb-20 lg:mb-16 text-grey">
                  {newsArticle.lead.text}
                </h2>
              )}
              {newsArticle.body.html && (
                <div
                  className="rte"
                  dangerouslySetInnerHTML={{ __html: newsArticle.body.html }}
                />
              )}
            </div>
            <div className="lg:flex mx-4 lg:mx-0 lg:-mx-2 text-2xl">
              <div className="block lg:w-1/2 lg:px-2 mb-8">
                <Link to="/news" className="text-charcoal">
                  Back to all Forté News
                </Link>
              </div>
              <div className="block lg:w-1/2 lg:px-2 mb-8">
                {next && (
                  <Link
                    to={`/news/${next.uid}`}
                    rel="next"
                    className="text-charcoal"
                  >
                    Next Story
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageArticleQuery = graphql`
  query PostsByUid($uid: String) {
    allPrismicNewsArticle(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          first_publication_date(formatString: "Do MMMM YYYY")
          data {
            title
            lead {
              html
              text
            }
            image {
              url
              alt
              localFile {
                childImageSharp {
                  id
                }
              }
            }
            body {
              html
            }
            category {
              uid
              document {
                data {
                  title
                  subtitle
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ArticleTemplate
